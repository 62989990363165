define('webapp/components/campaign/entry-search', ['exports', 'webapp/utils/scroll-to'], function (exports, _scrollTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    myStorage: Ember.inject.service(),
    languageService: Ember.inject.service(),
    cachedData: Ember.inject.service(),
    searchTerm: '',

    entryIds: Ember.computed('campaign.entries', function () {
      var entryRels = this.get('campaign.entries');
      return entryRels.map(function (x) {
        return x.item._id;
      });
    }),

    didInsertElement: function didInsertElement() {
      var selectedEntryId = this.get('selectedEntryId');
      var entryRels = this.get('campaign.entries') || [];
      var entries = entryRels.map(function (x) {
        return x.item;
      });

      if (selectedEntryId) {
        var selectedEntry = entries.find(function (x) {
          return x._id === selectedEntryId;
        });
        this.set('items', [selectedEntry]);
        this.set('selectedEntry', selectedEntry);
      } else {
        if (entries.length <= 9) {
          this.set('items', entries);
        } else {
          // this.search('').then(items => {
          //   this.set('items', items);
          // });
        }
      }
    },
    getGeoResult: function getGeoResult(searchTerm) {
      if (!searchTerm) {
        return Ember.RSVP.resolve();
      }

      // TODO use browser or ip location
      var isAustria = !isNaN(Number(searchTerm)) && searchTerm.length === 4;
      var center = isAustria ? { lat: 47.72, lon: 14.76 } : { lat: 51.16, lon: 10.45 };

      var myStorage = this.get('myStorage');
      return myStorage.geocoder.ajaxPost({
        param: 'search_place',
        data: {
          searchTerm: searchTerm,
          language: this.get('languageService.selectedLanguage'),
          proximity: center
        }
      }).then(function (res) {
        var searchTermLower = searchTerm.toLowerCase();
        var firstRes = res.find(function (x) {
          return x.text.toLowerCase() === searchTermLower;
        }) || res[0];

        if (firstRes) {
          var _firstRes$center = _slicedToArray(firstRes.center, 2),
              lon = _firstRes$center[0],
              lat = _firstRes$center[1];

          return { lat: lat, lon: lon };
        }
      });
    },
    search: function search(searchTerm) {
      var _this = this;

      var myStorage = this.get('myStorage');

      return this.getGeoResult(searchTerm).then(function (latLon) {
        var searchData = {
          itemIds: _this.get('entryIds'),
          size: 10,
          itemTypes: ['entry'],
          aggregations: false
        };
        if (latLon) {
          searchData.location = latLon;
          searchData.sort = 'geoDistance';
        } else {
          searchData.searchTerm = searchTerm;
        }
        return myStorage.search.ajaxPost({
          data: searchData,
          query: {
            lang: _this.get('languageService.selectedLanguage'),
            site: _this.get('cachedData.cachedSite._id')
          }
        }).then(function (res) {
          return res.hits;
        });
      });
    },


    actions: {
      search: function search() {
        var _this2 = this;

        this.search(this.get('searchTerm')).then(function (items) {
          _this2.set('items', items);
        });
      },
      selectEntry: function selectEntry(entry) {
        var _this3 = this;

        this.set('selectedEntry', entry);

        // Scrollevent
        setTimeout(function () {
          (0, _scrollTo.scrollToElement)('#' + _this3.get('campaignFormId'), { offset: 100 });
        }, 300);
      }
    }
  });
});